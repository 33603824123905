import request from "@/utils/request";

// 查询报警联系人列表
export function getRouters() {
  return request({
    url: "/getRouters",
    method: "get",
  });
}

// 查询菜单下拉树结构
export function treeselect(query) {
  return request({
    url: "/system/menu/treeselect?parentId=" + query,
    method: "get",
  });
}

// 根据角色ID查询菜单下拉树结构
export function roleMenuTreeselect(roleId) {
  return request({
    url: "/system/menu/roleMenuTreeselect/" + roleId,
    method: "get",
  });
}
