import { getUserProfile } from "@/api/user";
import { logout } from "@/api/login";
import { removeToken } from "@/utils/cookie";

const user = {
  state: {
    userid: "",
    username: "",
    unitname: "",
    roleid: "",
    rolename: "",
    isadmin: false,
    usertype: "",
  },
  mutations: {
    SET_USERID: (state, userid) => {
      state.userid = userid;
    },
    SET_USERNMAE: (state, username) => {
      state.username = username;
    },
    SET_UNITNAME: (state, unitname) => {
      state.unitname = unitname;
    },
    SET_ROLEID: (state, roleid) => {
      state.roleid = roleid;
    },
    SET_ROLENAME: (state, rolename) => {
      state.rolename = rolename;
    },
    SET_ISADMIN: (state, isadmin) => {
      state.isadmin = isadmin;
    },
    SET_USERTYPE: (state, usertype) => {
      state.usertype = usertype;
    },
  },
  actions: {
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserProfile()
          .then((res) => {
            const user = res.data;
            commit("SET_USERID", user.userId);
            commit("SET_USERNMAE", user.userName);
            commit("SET_UNITNAME", user.nickName);
            commit("SET_ROLEID", user.roles[0].roleId);
            commit("SET_ROLENAME", user.roles[0].roleName);
            commit("SET_ISADMIN", user.userType != "3");
            commit("SET_USERTYPE", user.userType);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    LogOutSystem({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            removeToken();
            commit("SET_ACTIVEID", 1);
            commit("SET_USERID", "");
            commit("SET_USERNMAE", "");
            commit("SET_UNITNAME", "");
            commit("SET_ROLEID", "");
            commit("SET_ROLENAME", "");
            commit("SET_ISADMIN", false);
            localStorage.clear();
            resolve();
          })
          .catch((error) => {
            resolve();
            reject(error);
          });
      });
    },
  },
};

export default user;
