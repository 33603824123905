import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data: data,
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}
