import router from "./router";
import store from "./store";
import { getToken } from "@/utils/cookie";

router.beforeEach((to, from, next) => {
  if (to.path === "/home") {
    next();
  } else {
    if (getToken()) {
      if (!store.getters.userid || !store.getters.roleid) {
        store
          .dispatch("getUserInfo")
          .then(() => {
            store
              .dispatch("GenerateRoutes")
              .then((accessRoutes) => {
                accessRoutes.forEach((item) => {
                  router.addRoute(item);
                });
                next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
              })
              .catch((err) => {
                store.dispatch("setActive", 1);
                next({ path: "/" });
              });
          })
          .catch(() => {
            store
              .dispatch("LogOutSystem")
              .then(() => {
                store.dispatch("setActive", 1);
                next({ path: "/" });
              })
              .catch((err) => {
                console.log(err);
                store.dispatch("setActive", 1);
                next({ path: "/" });
              });
          });
      } else {
        next();
      }
    } else {
      store.dispatch("setActive", 1);
      next(`/home?redirect=${to.fullPath}`); // 否则全部重定向到登录页
    }
  }
});
