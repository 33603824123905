import Cookies from "js-cookie";

const TokenKey = "accessToken";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 7 });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getKey(key) {
  return Cookies.get(key);
}

export function setKey(key, value) {
  return Cookies.set(key, value);
}

export function removekey(key) {
  console.log(key);
  return Cookies.remove(key);
}
