const app = {
  state: {
    activeid: localStorage.getItem("activeid") || 1,
  },
  mutations: {
    SET_ACTIVEID: (state, id) => {
      state.activeid = id;
      localStorage.setItem("activeid", id);
    },
  },
  actions: {
    setActive({ commit }, id) {
      commit("SET_ACTIVEID", id);
    },
  },
};

export default app;
