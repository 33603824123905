import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../components/Layout.vue";
import System from "../components/System.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/staging",
        name: "Staging",
        component: () => import("../components/Staging.vue"),
        redirect: "/staging/home",
        children: [
          {
            path: "/staging/home",
            name: "StagingHome",
            meta: {
              title: "我的工作台",
            },
            component: () => import("../views/Staging/StagingHome.vue"),
          },
          {
            path: "/staging/myinfo",
            name: "MyInfo",
            meta: {
              title: "个人信息",
            },
            component: () => import("../views/Staging/MyInfo.vue"),
          },
          {
            path: "/staging/newsinfo/:id",
            name: "NewsInfo",
            meta: {
              title: "公告详情",
            },
            component: () => import("../views/Staging/NewsInfo.vue"),
          },
        ],
      },
      {
        path: "/findPwd",
        name: "FindPwd",
        component: () => import("../views/FindPwd.vue"),
      },
      {
        path: "/system",
        name: "System",
        component: System,
        redirect: "/system/users",
        children: [
          {
            path: "/system/users",
            name: "Users",
            meta: {
              title: "用户管理",
              icon: "&#xe625;",
              ismenu: true,
            },
            component: () => import("../views/System/Users.vue"),
          },
          {
            path: "/system/roles",
            name: "Roles",
            meta: {
              title: "角色管理",
              icon: "&#xe636;",
              ismenu: true,
            },
            component: () => import("../views/System/Roles.vue"),
          },
          {
            path: "/system/operatelog",
            name: "Operatelog",
            meta: {
              title: "操作日志",
              icon: "&#xe674;",
              ismenu: true,
            },
            component: () => import("../views/System/Operatelog.vue"),
          },
          {
            path: "/system/loginlog",
            name: "Loginlog",
            meta: {
              title: "登录日志",
              icon: "&#xe674;",
              ismenu: true,
            },
            component: () => import("../views/System/Loginlog.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRoutes,
});

export default router;
