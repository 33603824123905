<!-- Layout -->
<template>
  <div class="layout">
    <div class="header">
      <div class="h_nav">
        <div class="h_nav_cont">
          <div class="h_nav_item">
            <img src="../assets/images/logo.png" alt="" class="logo" />
          </div>
          <div
            class="h_nav_item"
            :class="{ active: item.id == activeid }"
            v-for="(item, index) in hav_menus"
            :key="index"
            @click="go(item)"
          >
            {{ item.title }}
          </div>

          <div
            v-if="isadmin"
            class="h_nav_item"
            :class="{ active: activeid == 5 }"
            @click="go({ id: 5, url: '/system' })"
          >
            系统管理
          </div>
        </div>
        <div class="logout" v-if="getToken()">
          <i class="iconfont" @click="logout"></i>
        </div>
      </div>
    </div>

    <template>
      <div class="content">
        <router-view />
      </div>
    </template>

    <div class="footer">
      Copyright © 2017-2019 上海有为信息技术有限公司 | 备案信息:
      沪ICP备05058269号-2
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/cookie";
import { mapGetters } from "vuex";

export default {
  name: "Layout",
  computed: {
    ...mapGetters(["activeid", "isadmin"]),
  },
  data() {
    return {
      hav_menus: [
        {
          id: 1,
          url: "/home",
          title: "首页",
        },
        {
          id: 2,
          url: "/staging",
          title: "我的工作台",
        },
        {
          id: 3,
          url: "",
          title: "有为云管家系统",
        },
        {
          id: 4,
          url: "/operation",
          title: "安全运维管理系统",
        },
      ],
    };
  },

  methods: {
    getToken,
    go(item) {
      if (item.id == 3) {
        this.$message({
          showClose: true,
          message: "很抱歉,您没有权限",
          type: "warning",
        });
      } else {
        if (getToken()) {
          localStorage.setItem("activeid", item.id);
          this.$store.dispatch("setActive", item.id);
        }
        this.$router.push({
          path: item.url,
        });
      }
    },
    logout() {
      let _this = this;
      this.$confirm("确定要退出系统吗?")
        .then(function () {
          return _this.$store.dispatch("LogOutSystem");
        })
        .then(() => {
          if (_this.$route.name == "Home") {
            _this.$router.go(0);
          } else {
            _this.$router.push({ name: "Home" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.layout {
  min-height: 100vh;
  background-color: rgb(242, 242, 242);
}
.header {
  height: 70px;
  line-height: 70px;
  background-color: #4983bc;
  .h_nav {
    width: 1270px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    .h_nav_cont {
      width: 1270px;
      margin: 0px auto;
      display: flex;
      align-items: center;
      .h_nav_item {
        box-sizing: border-box;
        height: 70px;
        display: flex;
        padding: 0px 10px;
        align-items: center;
        margin-right: 40px;
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        .logo {
          width: 50px;
          height: 50px;
        }
      }
      .active {
        border-bottom: 3px solid #cb3029;
      }
    }
    .logout {
      width: 50px;
      text-align: right;
      i {
        color: #fff;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }
}
.content {
  min-height: calc(100vh - 120px);
  margin: 0px auto;
  margin-top: 10px;
  padding-top: 10px;
}

.footer {
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
}
</style>