import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/css/index.scss";
import "element-ui/lib/theme-chalk/index.css";

import ElementUI, { Table } from "element-ui";

const fixElTableErr = (table) => {
  const oldResizeListener = table.methods.resizeListener;
  table.methods.resizeListener = function () {
    window.requestAnimationFrame(oldResizeListener.bind(this));
  };
};

import { download } from "@/utils/request";

Vue.prototype.download = download;

fixElTableErr(Table);

Vue.config.productionTip = false;
Vue.use(ElementUI);

import "./permission";

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
