<!--  -->
<template>
  <div class="Operation">
    <div class="Lmenu">
      <div class="tit">安全运维管理系统</div>
      <template v-for="(item, index) in menus">
        <div
          class="menu_item"
          :class="{ active_menu_item: active_path.indexOf(item.path) != -1 }"
          v-if="!item.hidden"
          :key="index"
          @click="link(item)"
        >
          <i class="iconfont" v-html="item.meta.icon"></i>
          <span class="title">{{ item.meta.title }}</span>
        </div>
      </template>
    </div>
    <div class="Rcont">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Operation",
  components: {},
  data() {
    return {
      menus: [],
      active_path: "",
    };
  },

  created() {
    this.menus = this.$store.state.permission.addRoutes.children;
  },

  methods: {
    link(item) {
      this.$router.push({ name: item.name });
      this.active_path = item.path;
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        this.active_path = val.path;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang='scss' scoped>
.Operation {
  width: 1270px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.Lmenu {
  min-height: calc(100vh - 120px);
  width: 200px;
  background-color: #fff;
  .tit {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #333333;
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #e6e6e6;
  }
  .menu_item {
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    line-height: 50px;
    color: #303133;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      background-color: #ecf5ff;
    }
    .iconfont {
      padding-right: 8px;
      font-size: 20px;
    }
  }
  .active_menu_item {
    color: #cb3029;
  }
}
.Rcont {
  min-height: calc(100vh - 120px);
  background-color: #fff;
  width: calc(1270px - 210px);
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
}
</style>